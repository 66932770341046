import { apiGetAuth, apiPostAuth, apiPostAuthWithImage, apiPutAuth } from '@/utils/api'; 
import { API_MASTER, API_AUTH } from '@/utils/api-url'; 

const state = { 
  invoice_events: [], 
  invoice_event: null, 
  total: 0, 
  loading: false, 
  error: null 
}; 

const mutations = { 
  SET_INVOICE_EVENTS(state, invoice_events) { 
    state.invoice_events = invoice_events; 
  }, 
  SET_INVOICE_EVENT(state, invoice_event) { 
    state.invoice_event = invoice_event; 
  }, 
  SET_TOTAL(state, total) { 
    state.total = total; 
  }, 
  SET_LOADING(state, isLoading) { 
    state.loading = isLoading; 
  }, 
  SET_ERROR(state, error) { 
    state.error = error; 
  } 
}; 

const actions = { 
  async getInvoiceEvent({ commit }, { limit, page, search }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.INVOICE_EVENT}?limit=${limit}&page=${page}&search=${search}`); 
      if (response.data.status_code === 200) { 
        
        commit('SET_INVOICE_EVENTS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async createInvoiceEvent({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPostAuthWithImage(API_MASTER.CREATE_INVOICE_EVENT, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async updateInvoiceEvent({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPutAuth(API_MASTER.UPDATE_INVOICE_EVENT(payload.id), payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async getInvoiceEventById({ commit }, inv,poNumber) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.INVOICE_EVENT}/by-invoice-number?invoice_number=${inv}&po_number=${poNumber}`); 
      if (response.data.status_code === 200) { 
        commit('SET_INVOICE_EVENT', response.data.data); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getInvoiceByPo({ commit }, po) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.PO_EVENT}/by-po-number?po_number=${po}`); 
      if (response.data.status_code === 200) { 
        return response.data.data;
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getModule({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.MODULE}`); 
      if (response.data.status_code === 200) {
        // Return the mapped module data
        return response.data.data.map(module => ({
          id: module.id,
          name: module.module_name // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getParent({ commit }, { module_id }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.PARENT}?module_id=${module_id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data.map(parent => ({
          id: parent.id,
          name: parent.module_name // Adjust to use module_name for parents
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getEventById({ commit }, id ) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.EVENT}/${id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async PrintRFP({commit}, payload) {
    // commit('SET_LOADING', true);
    try { 
      const response = await apiPostAuth(`${API_MASTER.INVOICE_EVENT}/print-rfp`, payload); 
      if (response.data.status_code === 200) { 
        console.log(response.data.data);
        
        return response.data.data; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      // commit('SET_LOADING', false); 
    } 
  },

  async getItemAttachments({ commit }) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.ITEM_ATTACHMENT}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        return response.data.data.data.map(item => ({
          id: item.item_code,
          value: item.item_code,
          name: item.item_name,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getItems({ commit }) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.ITEM}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        return response.data.data.data.map(item => ({
          id: item.item_code,
          value: item.item_code,
          name: item.item_name,
          percentage_badan : item.percentage_badan,
          percentage_perorangan : item.percentage_perorangan,
          pph_badan : item.pph_badan,
          pph_perorangan : item.pph_perorangan
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getUserById({ commit }, id) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.USER}/${id}`); 
      if (response.data.status_code === 200) { 
        return response.data.data;
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

}; 

const getters = { 
  getInvoiceEvents(state) { 
    return state.invoice_events; 
  }, 
  getInvoiceEvent(state) { 
    return state.invoice_event; 
  }, 
  getTotalInvoiceEvents(state) { 
    return state.total; 
  }, 
  isLoading(state) { 
    return state.loading; 
  }, 
  getError(state) { 
    return state.error; 
  } 
}; 

export default { 
  namespaced: true, 
  state, 
  mutations, 
  actions, 
  getters 
};
