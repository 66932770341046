import { apiGetAuth, apiPostAuth, apiPutAuth } from "@/utils/api";
import { API_AUTH, API_MASTER } from "@/utils/api-url";

const state = {
  users: [],
  user: null,
  total: 0,
  loading: false,
  error: null,
  brands: [],
  branches: [],
  sub_region: [],
};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_BRANDS(state, brands) {
    state.brands = brands;
  },
  SET_BRANCHES(state, branches) {
    state.branches = branches;
  },
  SET_SUB_REGION(state, sub_region) {
    state.sub_region = sub_region;
  },
};

const actions = {
  async getBrand({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(
        `${API_MASTER.ADVANCE_OTHERS_BRAND}?limit=100&page=1&search=`
      );
      if (response.data.status_code === 200) {
        commit("SET_BRANDS", response.data.data.data);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async getUser({ commit }, { limit, page, search }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(
        `${API_AUTH.USER}?limit=${limit}&page=${page}&search=${search}`
      );
      if (response.data.status_code === 200) {
        commit("SET_USERS", response.data.data.data);
        commit("SET_TOTAL", response.data.data.total);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async createUser({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await apiPostAuth(API_AUTH.CREATE_USER, payload);
      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async updateUser({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await apiPutAuth(
        API_AUTH.UPDATE_USER(payload.id),
        payload
      );
      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getUserById({ commit }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.USER}/${id}`);
      if (response.data.status_code === 200) {
        commit("SET_USER", response.data.data);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getModule({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.MODULE}`);
      if (response.data.status_code === 200) {
        // Return the mapped module data
        return response.data.data.map((module) => ({
          id: module.id,
          value: module.id,
          name: module.module_name,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getParent({ commit }, { module_id }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(
        `${API_AUTH.PARENT}?module_id=${module_id}`
      );
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data.map((parent) => ({
          id: parent.id,
          value: parent.id,
          name: parent.module_name,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getDepartment({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.DEPARTMENT}`);
      if (response.data.status_code === 200) {
        // Return the mapped department data
        return response.data.data.map((department) => ({
          id: department.id,
          value: department.id,
          name: department.name,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getRegion({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.REGION}`);
      if (response.data.status_code === 200) {
        // Return the mapped region data
        return response.data.data.map((region) => ({
          id: region.id,
          value: region.id,
          name: region.name,
          region_code: region.code,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getSubRegion({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.SUB_REGION}`);
      if (response.data.status_code === 200) {
        // Return the mapped sub_region data
        return response.data.data.map((sub_region) => ({
          id: sub_region.id,
          value: sub_region.id,
          name: sub_region.name,
          sub_region_code: sub_region.code,
          region_id: sub_region.region_id,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getSubRegionById({ commit }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.SUB_REGION}?id=${id}`);
      if (response.data.status_code === 200) {
        // Return the mapped sub_region data
        return response.data.data.map((sub_region) => ({
          id: sub_region.id,
          value: sub_region.id,
          name: sub_region.name,
          region_id: sub_region.region_id,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getBranches({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}`);
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map((branches) => ({
          id: branches.id,
          value: branches.id,
          name: branches.name,
          branch_code: branches.code,
          sub_region_id: branches.sub_region_id,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getBranchesById({ commit }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}?id=${id}`);
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map((branches) => ({
          id: branches.id,
          value: branches.id,
          name: branches.name,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async getBranchesByRegion({ commit }, { subregion_id, region_id }) { 
    console.log(region_id);
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}?id=${subregion_id}&region_id=${region_id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map(branches => ({
          id: branches.id,
          value: branches.id,
          name: branches.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getRoles({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.ROLES}`); 
      if (response.data.status_code === 200) {
        // Return the mapped roles data
        return response.data.data.map((roles) => ({
          id: roles.id,
          value: roles.id,
          name: roles.name,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

const getters = {
  getSubRegions(state) {
    return state.sub_region;
  },
  getAllBranches(state) {
    return state.branches;
  },
  getBrands(state) {
    return state.brands;
  },
  getUsers(state) {
    return state.users;
  },
  getUser(state) {
    return state.user;
  },
  getTotalUsers(state) {
    return state.total;
  },
  isLoading(state) {
    return state.loading;
  },
  getError(state) {
    return state.error;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
