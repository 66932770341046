<template>
  <aside class="main-sidebar sidebar-light-primary">
    <!-- Brand Logo -->
    <img src="@/assets/logo/logo.png" alt="AdminLTE Logo" class="brand-image" style="opacity: .8">

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <router-link 
              to="/home" 
              class="nav-link" 
              :class="{ active: $route.path === '/home' }"
            >
              <i class="nav-icon fas fa-home"></i>
              <p>Home</p>
            </router-link>
          </li>
          <!-- Static Dashboard Menu -->
          <li class="nav-item">
            <router-link 
              to="/dashboard" 
              class="nav-link" 
              :class="{ active: $route.path === '/dashboard' }"
            >
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li v-for="menu in computedMenus" :key="menu.title" class="nav-item menu-open" :class="{ 'menu-open': $route.path.includes(menu.path) }">
            <!-- Conditionally render either a router-link or a normal anchor for parent menus -->
            <template v-if="menu.children">
              <a 
                href="#" 
                class="nav-link"  
                :class="{ active: $route.path.includes(menu.path) }"
              >
                <i :class="menu.icon"></i>
                <p>
                  {{ menu.title }}
                  <i v-if="menu.children" class="right fas fa-angle-left"></i>
                </p>
              </a>
              <!-- Child Menus -->
              <ul class="nav nav-treeview">
                <li v-for="child in menu.children" :key="child.path" class="nav-item">
                  <router-link 
                    :to="menu.path+child.path" 
                    class="nav-link"
                    :class="{ active: $route.path.includes(menu.path+child.path) }"
                  >
                    <p>{{ child.title }}</p>
                  </router-link>
                </li>
              </ul>
            </template>
            <!-- If the menu does not have children, directly link to its path -->
            <template v-else>
              <router-link 
                :to="menu.path" 
                class="nav-link"
                :class="{ active: $route.path === menu.path }"
              >
                <i :class="menu.icon"></i>
                <p>{{ menu.title }}</p>
              </router-link>
            </template>
          </li>
          <li class="nav-item">
            <router-link 
              to="/heatmap" 
              class="nav-link" 
              :class="{ active: $route.path === '/heatmap' }"
            >
              <i class="nav-icon fas fa-map"></i>
              <p>Heatmap</p>
            </router-link>
          </li>
        </ul>
      </nav>

      <!-- Notification and Settings Section -->
      <div class="notification-section mt-5">
        <div class="nav-item">
          <i class="fas fa-bell"></i>
          <span>Notification</span>
        </div>
        <div class="nav-item">
          <i class="fas fa-cog"></i>
          <span>Settings</span>
        </div>
      </div>

      <!-- New Features Available Section -->
      <div class="new-features p-2">
        <div class="d-flex justify-content-between align-items-center">
          <strong>New Features Available</strong>
          <i class="fas fa-times"></i>
        </div>
        <p>Check out the new dashboard view. Pages now load faster.</p>
        <div class="d-flex justify-content-between">
          <a href="#">Dismiss</a>
          <a href="#">What's New?</a>
        </div>
      </div>

      <div class="user-profile d-flex align-items-center p-2" v-if="profile && profile.username">
        <img src="@/assets/logo/user.png" alt="User Avatar" class="img-circle elevation-2" style="width: 40px; height: 40px;">
        <div class="info ml-2">
          <span class="d-block">{{ profile.username }}</span>
          <small class="d-block">{{ profile.email }}</small>
        </div>
        <a href="/logout" class="ml-auto">
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </div>

      <!-- Show a loading state while profile is undefined -->
      <div class="user-profile d-flex align-items-center p-2" v-else>
        <img src="@/assets/logo/user.png" alt="User Avatar" class="img-circle elevation-2" style="width: 40px; height: 40px;">
        <div class="info ml-2">
          <span class="d-block">Loading...</span>
          <small class="d-block">Loading...</small>
        </div>
      </div>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export const dynamicMenuItems = [
  { 
    title: "Data Master", 
    path: "/data-master", 
    icon: "nav-icon fa fa-database", 
    children: [
      { title: "User", path: "/user", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Role", path: "/role", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Menu", path: "/menu", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Target Visit", path: "/target-visit", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Outlet", path: "/outlet", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Mapping Approval", path: "/mapping-approval", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Vendor", path: "/vendor", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Budget", path: "/budget", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
    ],
    open: true
  },
  { 
    title: "PO Transaction", 
    path: "/po-transaction", 
    icon: "nav-icon fa fa-book", 
    children: [
      { title: "Event", path: "/event", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Branding", path: "/branding", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
    ],
    open: false
  },
  { 
    title: "Invoice", 
    path: "/invoice", 
    icon: "nav-icon fa fa-book", 
    children: [
      { title: "Event", path: "/event", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Branding", path: "/branding", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
    ],
    open: false
  },
  { 
    title: "Program & Promo", 
    path: "/program-promo", 
    icon: "nav-icon fa fa-star",
    children: [
      { title: "Program", path: "/program", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Promo", path: "/promo", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
    ]
  },
  { title: "Event Management", path: "/event-management", icon: "nav-icon fa fa-calendar", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
  { title: "Mission Gamification", path: "/mission-gamification", icon: "nav-icon fa fa-trophy", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
  { 
    title: "Approval",
    path: "/approval", 
    icon: "nav-icon fa fa-circle", 
    children: [
      { title: "Vendor", path: "/vendor", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "PO Event", path: "/po-event", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "PO Branding", path: "/po-branding", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Invoice Event", path: "/invoice-event", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Invoice Branding", path: "/invoice-branding", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "CA Compensation", path: "/ca-compensation", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "CA Operational", path: "/ca-operational", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "CA Others", path: "/ca-others", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Settlement Compensation", path: "/settlement-compensation", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Settlement Operational", path: "/settlement-operational", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Settlement Others", path: "/settlement-others", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
    ]
  },
  { 
    title: "Advance",
    path: "/advance", 
    icon: "nav-icon fa fa-circle", 
    children: [
      { title: "Compensation", path: "/compensation", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Operational", path: "/operational", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Others", path: "/others", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
    ]
  },
  { 
    title: "Settlement",
    path: "/settlement", 
    icon: "nav-icon fa fa-circle", 
    children: [
      { title: "Compensation", path: "/compensation", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Operational", path: "/operational", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Others", path: "/others", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
    ]
  },
  { 
    title: "Report",
    path: "/report", 
    icon: "nav-icon fa fa-circle", 
    children: [
      { title: "Branding", path: "/branding", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Event", path: "/event", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Compensation", path: "/compensation", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Operational", path: "/operational", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Others", path: "/others", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
    ]
  }
];

export default {
  data() {
    return {
      dynamicMenus: dynamicMenuItems, // Default to dynamicMenuItems
    };
  },
  computed: {
    ...mapGetters('account', ['getUserProfile', 'getUserRoleMenu']),
    ...mapState('account', ['profile', 'roleMenu']),
    computedMenus() {
      return this.dynamicMenus;
    },
  },
  methods: {
    ...mapActions('account', ['fetchUserProfile', 'getRoleMenu']),
    async loadRoleMenu() {
      try {
        await this.getRoleMenu();
        await this.fetchUserProfile();
        
        // Check for production environment and update dynamicMenus
        console.log(process.env.VUE_APP_ENV_TYPE);
        
        if (process.env.VUE_APP_ENV_TYPE === 'production' && this.getUserRoleMenu.role.menu) {
          this.dynamicMenus = this.mapRoleMenuToDynamicMenu(this.getUserRoleMenu.role.menu);
        }
        console.log('Loaded menus:', this.dynamicMenus);
      } catch (error) {
        console.log('Error loading role menu or profile:', error);
      }
    },
    mapRoleMenuToDynamicMenu(roleMenu) {
      // Sort menu by menu.id in ascending order and map API response structure to match dynamicMenus
      return roleMenu
        .sort((a, b) => a.id - b.id) // Sort by id ascending
        .map(menu => ({
          title: menu.name,
          path: `${menu.path}`,
          icon: 'nav-icon fa fa-circle', // Default icon; adjust as necessary
          children: menu.sub_menu
            ? menu.sub_menu
                .sort((a, b) => a.id - b.id) // Sort sub_menu by id ascending
                .map(sub => ({
                  title: sub.name,
                  path: `${sub.path}`,
                  permission: {
                    create: sub.permission.CRE,
                    read: sub.permission.REA,
                    update: sub.permission.UPD,
                    delete: sub.permission.DEL,
                  },
                }))
            : null,
        }));
    }
  },
  watch: {
    '$route.path'() {
      this.dynamicMenus.forEach(menu => {
        if (menu.children) {
          menu.open = menu.children.some(child => this.$route.path.startsWith(child.path));
        }
      });
    },
  },
  mounted() {
    this.loadRoleMenu(); // Fetch data on mount
  },
};

</script>

<style>
/* Styles for new sections */
.notification-section {
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}

.notification-section .nav-item {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  color: #495057;
}

.notification-section .nav-item i {
  margin-right: 10px;
  color: #6c757d;
}

.new-features {
  background-color: #f8f9fa;
  border-radius: 5px;
  margin: 10px;
}

.new-features strong {
  color: #007bff;
}

.new-features p {
  margin: 5px 12px;
  font-size: 12px;
  color: #6c757d;
}

.new-features a {
  font-size: 12px;
  color: #007bff;
}

.user-profile {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  max-width: 100%;
  overflow: hidden;
}

.user-profile img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.user-profile .info {
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
}

.user-profile .info span,
.user-profile .info small {
  display: block;
  font-weight: bold;
  color: #343a40;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-profile .info small {
  font-weight: normal;
  font-size: 10px;
  color: #6c757d;
}

.user-profile a {
  color: #6c757d;
  margin-left: auto;
}

.user-profile a:hover {
  color: #343a40;
}

.nav-treeview .nav-item .nav-link {
  padding-left: 47px;
  color: #495057 !important;
}
.nav-sidebar .nav-link.active {
  background-color: #CCE1FA !important;
  color: #234670 !important;
}
</style>
