<template>
  <nav
    class="main-header navbar navbar-expand navbar-white navbar-light custom-navbar"
  >
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
      <!-- Breadcrumb / Title -->
      <li class="nav-item">
        <div
          v-if="childTitle && parentTitle !== childTitle"
          class="breadcrumb-container"
        >
          <a
            :href="parentRoutePath"
            class="breadcrumb-link page-parent-title"
            >{{ parentTitle }}</a
          >
          /
          <a href="#" class="breadcrumb-link page-child-title">{{
            childTitle
          }}</a>
        </div>
        <h1 class="page-title">{{ pageTitle }}</h1>
      </li>
    </ul>

    <!-- Right navbar links (search, notifications, etc.) -->
    <!-- <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
          <i class="fas fa-th-large"></i>
        </a>
      </li>
    </ul> -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      parentTitle: "", // Parent route title
      childTitle: "", // Child route title
      pageTitle: "", // Page title (or the child title)
      parentRoutePath: "", // Path to parent route
      headerTitle: "",
    };
  },
  watch: {
    // Whenever the route changes, update the breadcrumb
    $route: "updateBreadcrumb",
  },
  mounted() {
    this.updateBreadcrumb();
  },
  methods: {
    updateBreadcrumb() {
      // Get the parent and child routes from the matched routes array
      const matchedRoutes = this.$route.matched;
      const parentRoute = matchedRoutes.length > 1 ? matchedRoutes[0] : null;
      const childRoute =
        matchedRoutes.length > 1 ? matchedRoutes[1] : matchedRoutes[0];

      const pathSlices = parentRoute.path.split("/").filter(Boolean);

      // If the path has 2 slices, log the first one
      if (pathSlices.length === 2) {
        this.headerTitle = pathSlices[0]
          .replace(/-/g, " ")
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
          .replace(/\bPo\b/g, "PO")
          .replace(/\bData\b/g, "")
          .trim();
      } else {
        this.headerTitle = "";
      }

      // Update titles from the routes' meta information
      this.parentTitle = parentRoute ? parentRoute.meta.title || "Parent" : "";
      this.childTitle =
        childRoute && matchedRoutes.length > 1
          ? childRoute.meta.title || ""
          : "";
      this.pageTitle = this.childTitle || this.parentTitle; // Fallback to parent if no child
      this.parentRoutePath = parentRoute ? parentRoute.path : "";
    },
  },
};
</script>

<style scoped>
/* Custom styles */
.custom-navbar {
  height: 100px; /* Set navbar height */
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: space-between; /* Spread content horizontally */
  padding-left: 20px;
  padding-right: 20px;
}

/* Breadcrumb and title styling */
.breadcrumb-container {
  font-size: 14px;
  color: #007bff;
}

.breadcrumb-link {
  color: #007bff;
  text-decoration: none;
  margin-right: 5px;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}

.page-title {
  margin: 5px 0 0 0;
  font-size: 28px;
  font-weight: bold;
  color: #343a40;
}

/* Hide pushmenu on larger screens if sidebar is expanded */
@media (min-width: 768px) {
  .nav-item .nav-link {
    display: inline-block;
  }
  /* If sidebar is not collapsed, hide the pushmenu */
  body:not(.sidebar-collapse) .nav-item .nav-link[data-widget="pushmenu"] {
    display: none;
  }
}

/* Mobile styles */
@media (max-width: 767.98px) {
  .breadcrumb-container {
    font-size: 12px;
  }
  .page-title {
    font-size: 20px;
  }
}
</style>
