import {
  apiGetAuth,
  apiPutAuth,
  apiDeleteAuth,
  apiPostAuthWithImage,
} from "@/utils/api";
import { API_MASTER, API_AUTH } from "@/utils/api-url";
import { objectToFormData } from "@/utils/helper";

const state = {
  advance_others: [],
  advance_other: null,
  total: 0,
  loading: false,
  error: null,
};

const mutations = {
  SET_ADVANCE_OTHERS(state, advance_others) {
    state.advance_others = advance_others;
  },
  SET_ADVANCE_OTHER(state, advance_other) {
    state.advance_other = advance_other;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

const actions = {
  async getAdvanceOthers({ commit }, { limit, page, search }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(
        `${API_MASTER.ADVANCE_OTHERS}?limit=${limit}&page=${page}&search=${search}`
      );
      if (response.data.status_code === 200) {
        commit("SET_ADVANCE_OTHERS", response.data.data.data);
        commit("SET_TOTAL", response.data.data.total);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getAdvanceOthersWithStatus({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(
        `${API_MASTER.ADVANCE_OTHERS}?limit=100&page=1&status=${payload.status}&search=${payload.search}`
      );
      if (response.data.status_code === 200) {
        return response.data.data?.data?.map((ca) => ({
          id: ca.ca_number,
          value: ca.ca_number,
          name: ca.ca_number, // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async createAdvanceOther({ commit }, payload) {
    console.log("create");

    commit("SET_LOADING", true);
    try {
      console.log(API_MASTER.CREATE_ADVANCE_OTHERS);
      let formData = objectToFormData(payload);
      const response = await apiPostAuthWithImage(
        API_MASTER.CREATE_ADVANCE_OTHERS,
        formData
      );
      console.log(response);

      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async updateAdvanceOther({ commit }, payload) {
    commit("SET_LOADING", true);
    console.log(payload);

    try {
      const response = await apiPutAuth(
        API_MASTER.UPDATE_ADVANCE_OTHERS,
        payload
      );
      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async deleteAdvanceOther({ commit }, payload) {
    commit("SET_LOADING", true);

    try {
      const response = await apiDeleteAuth(
        `${API_MASTER.UPDATE_ADVANCE_OTHERS}?ca_number=${payload.ca_number}`
      );
      console.log(response);

      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getAdvanceOtherById({ commit }, ca_number) {
    commit("SET_LOADING", true);

    try {
      const response = await apiGetAuth(
        `${API_MASTER.DETAIL_ADVANCE_OTHERS}?ca_number=${ca_number}`
      );
      if (response.data.status_code === 200) {
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getBudgetOwner({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(API_MASTER.ADVANCE_OTHERS_BUDGET);
      if (response.data.status_code === 200) {
        return response.data.data.map((budgetOwner) => ({
          id: budgetOwner.name,
          value: budgetOwner.name,
          name: budgetOwner.name, // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getBrand({ commit }, search = "") {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(
        `${API_MASTER.ADVANCE_OTHERS_BRAND}?limit=100&page=1&search=${search}`
      );
      if (response.data.status_code === 200) {
        return response.data.data.data.map((brand) => ({
          id: brand.brand_code,
          value: brand.brand_lob,
          name: brand.brand_name, // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getExpenses({ commit }, search = "") {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(
        API_MASTER.ADVANCE_OTHERS_EXPENSE + "?type=others&search=" + search
      );
      if (response.data.status_code === 200) {
        return response.data.data.map((expense) => ({
          id: expense.id,
          name: expense.expense_name,
          value: expense.expense_code,
          expense_code: expense.expense_code,
          expense_name: expense.expense_name,
          expense_category_code: expense.expense_category_code,
          expense_category_name: expense.expense_category_name,
          budget_owner: expense.bugdet_owner,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getEventById({ commit }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_MASTER.EVENT}/${id}`);
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getBranches({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}`);
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map((branches) => ({
          id: branches.id,
          value: branches.id,
          name: branches.name,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getOutlet({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(
        `${API_MASTER.BR_OUTLET}?limit=1000&page=1`
      );
      if (response.data.status_code === 200) {
        commit("SET_BR_OUTLETS", response.data.data.data);
        commit("SET_TOTAL", response.data.data.total);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getTaxByCodes({ commit }, type) {
    commit("SET_LOADING", true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.TAX}?type=${type}`);
      if (response.data.status_code === 200) {
        // Return the tax codes data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getItems({ commit }) {
    commit("SET_LOADING", true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.ITEM}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        return response.data.data.data.map((item) => ({
          id: item.item_code,
          value: item.item_code,
          name: item.item_name,
          percentage_badan: item.percentage_badan,
          percentage_perorangan: item.percentage_perorangan,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getUserById({ commit }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await apiGetAuth(`${API_AUTH.USER}/${id}`);
      if (response.data.status_code === 200) {
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  setSelectedAdvanceOther({ commit }, poEvent) {
    commit("SET_ADVANCE_OTHER", poEvent);
  },
};

const getters = {
  getAdvanceOthers(state) {
    return state.advance_others;
  },
  getAdvanceOther(state) {
    return state.advance_other;
  },
  getTotalAdvanceOthers(state) {
    return state.total;
  },
  isLoading(state) {
    return state.loading;
  },
  getError(state) {
    return state.error;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
